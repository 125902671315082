<template>
  <div :class="['flex-0 grid p-4 bg-white rounded-b-lg border-gray-300 whitespace-no-wrap border-t items-center gap-2', {
    'overflow-x-auto': scrollable
  }]">
    <slot
      class="flex justify-end gap-2 mb-2"
      name="error"
    />
    <div :class="['flex gap-2', {
      'justify-between': split,
      'justify-end': !split,
    }]">

      <slot />
    </div>
  </div>
</template>

<script>
export default {

  name: "p-dialog-actions",

  props: {
    split: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: true
    }
  }

}
</script>